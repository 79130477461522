import $ from 'jquery'
import Splide from '@splidejs/splide'
import '@splidejs/splide/dist/css/splide.min.css'
import './main.scss'

const initializeSplide = function () {
  const perPageDesktop = $('.testimonials--wrapper.splide').data(
    'per-page-desktop'
  )
  const perPageTablet = $('.testimonials--wrapper.splide').data(
    'per-page-tablet'
  )
  const perPageMobile = $('.testimonials--wrapper.splide').data(
    'per-page-mobile'
  )
  const paddingSizeDesktop = $('.testimonials--wrapper.splide').data(
    'padding-size-desktop'
  )
  const paddingSizeTablet = $('.testimonials--wrapper.splide').data(
    'padding-size-tablet'
  )
  const paddingSizeMobile = $('.testimonials--wrapper.splide').data(
    'padding-size-mobile'
  )
  return document
    .querySelectorAll('.testimonials--wrapper.splide')
    .forEach((carousel) => {
      console.log(carousel.classList)
      let arrowPath =
        'M15.3438 3.23438C14.5625 4.01562 14.6406 5.1875 15.3438 5.96875L24.7969 14.875H2.375C1.28125 14.875 0.5 15.7344 0.5 16.75V19.25C0.5 20.3438 1.28125 21.125 2.375 21.125H24.7969L15.3438 30.1094C14.6406 30.8906 14.6406 32.0625 15.3438 32.8438L17.0625 34.5625C17.8438 35.2656 19.0156 35.2656 19.7188 34.5625L34.9531 19.3281C35.6562 18.625 35.6562 17.4531 34.9531 16.6719L19.7188 1.51562C19.0156 0.8125 17.8438 0.8125 17.0625 1.51562L15.3438 3.23438Z'

      if (carousel.classList.contains('icon-style-outline')) {
        arrowPath =
          'M36.3536 25.3536C36.5488 25.1583 36.5488 24.8417 36.3536 24.6464L33.1716 21.4645C32.9763 21.2692 32.6597 21.2692 32.4645 21.4645C32.2692 21.6597 32.2692 21.9763 32.4645 22.1716L35.2929 25L32.4645 27.8284C32.2692 28.0237 32.2692 28.3403 32.4645 28.5355C32.6597 28.7308 32.9763 28.7308 33.1716 28.5355L36.3536 25.3536ZM14 25.5H36V24.5H14V25.5Z'
      }

      return new Splide(carousel, {
        type: 'loop',
        perPage: perPageDesktop,
        perMove: 1,
        padding: {
          right: paddingSizeDesktop,
          left: paddingSizeDesktop,
        },
        breakpoints: {
          1023: {
            perPage: perPageTablet,
            padding: {
              right: paddingSizeTablet,
              left: paddingSizeTablet,
            },
          },
          767: {
            perPage: perPageMobile,
            padding: {
              right: paddingSizeMobile,
              left: paddingSizeMobile,
            },
          },
        },
        classes: {
          // Add classes for arrows.
          arrows: 'splide__arrows clp_testimonials-arrows',
          arrow: 'splide__arrow clp_testimonials-arrow',
          prev: 'splide__arrow--prev clp_testimonials-prev',
          next: 'splide__arrow--next clp_testimonials-next',
          // Add classes for pagination.
          pagination: 'splide__pagination clp_testimonials-pagination', // container
          page: 'splide__pagination__page clp_testimonials-page', // each button
        },
        arrowPath,
      }).mount()
    })
}

$(document).on('simplero:section:load', initializeSplide)
$(initializeSplide)
